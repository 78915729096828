

.small .toggle-alternate {
    margin-top: 0;
}

.small .toggle-alternate label {
    top: 4px;
}

.small .toggle-alternate input {
    width: 55px;
}

.small .switch {
    height: 21px;
    width: 21px;
}

.toggle-bg {
    background: #ffffff;
    border-radius: 32px;
    border: 1px solid #e6e6e6;
    display: block;
    float: left;
    height: 25px;
    position: relative;
    width: 55px;
    -moz-transition-duration: 0.2s;
    -webkit-transition-duration: 0.2s;
    -o-transition-duration: 0.2s;
    transition-duration: 0.2s;
}

.toggle-bg:hover {
    border-color: #d9d9d9;
}

.toggle-bg.on {
    background: #00a76f;
    border-color: #051316;
    position: relative;
    top: 3px;
    left: 26px;
}

.toggle-bg input {
    margin: 0;
    padding: 0;
    width: 55px;
    height: 100%;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    -webkit-opacity: 0;
    opacity: 0;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
    z-index: 2;
    zoom: 1; filter: alpha(opacity=0);
    /* initial toggle position */
    /* final relative toggle position */
}

.toggle-bg input:checked ~ .switch {
    left: 2px;
}

.toggle-bg input ~ :checked ~ .switch {
    left: 30.25px;
}

.toggle-bg input:checked {
    z-index: 0;
}

.switch {
    top: 1px;
    background: #ffffff;
    border-radius: 32px;
    border: 1px solid #d9d9d9;
    display: block;
    float: left;
    height: 25px;
    width: 25px;
    left: -1px;
    position: relative;
    z-index: 1;
    -moz-transition-duration: 0.2s;
    -webkit-transition-duration: 0.2s;
    -o-transition-duration: 0.2s;
    transition-duration: 0.2s;
}

.switch.on {
    box-shadow: -3px 3px 8px rgba(0, 0, 0, 0.06), 6px 6px 24px rgba(0, 0, 0, 0.08);
}

.switch.off {
    box-shadow: -3px 3px 8px rgba(0, 0, 0, 0.06), 6px 6px 24px rgba(0, 0, 0, 0.08);
}

.toggle-alternate {
    clear: both;
    margin: 8px 0 0;
    width: 55px;
}

.toggle-alternate input {
    width: 55px;
}

.toggle-alternate input ~ :checked ~ .switch {
    left: 30.25px;
}

.toggle-alternate label {
    font-weight: 300;
    font-size: 10px;
    text-transform: uppercase;
    position: absolute;
    top: 3.125px;
}

.toggle-bg label.on {
    left: 10px;
    color: white;
    text-shadow: 0 1px 0 #09262b;
}

.toggle-bg label.off {
    right: 8px;
    color: #666;
}

.account-overview {
    width: 100%;
    position: relative;
    margin-bottom: 10px;
}

.account-overview .list-wrap {
    display: none;
    transform-origin: center top;
    background: #fff;
    padding: 10px 20px;
    border: 1px solid #09262b;
    z-index: 11;
    top: 30px;
    left: 0;
    width: 100%;
}
.biab_tab.biab_active{
    background-color: #5EC6CD;
    color: #ffffff;

}
.biab_tab.biab_active{
    background: #289fd3;

}
.biab_tab{
    display: inline-block;
    min-width: 40px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 2px;
    font-weight: bold;
    margin: 0 2px;
    padding: 0 2%;
    cursor: pointer;
}
.btn-settled{
    padding: 5px 10px;
    background: #a5d8ff;
    border-radius: 3px;
    border: none;
    cursor: pointer;
    position: relative;
    font-family: serif !important;
    left: -16px;
}
.smalldt input{
    width: 92px;
}
.toggle-alternate{
    position: relative;
    left: 26px;
    top: 3px;
}

.toggle-bg2 input {
    /* margin: 0; */
    /* padding: 0; */
    width: 55px;
    /* height: 100%; */
    -moz-opacity: 0;
    -khtml-opacity: 0;
    -webkit-opacity: 0;
    opacity: 0;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
    /* position: absolute; */
    /* left: 0; */
    /* top: 0; */
    cursor: pointer;
    z-index: 2;
    /* zoom: 1; */
    filter: alpha(opacity=0);
}
.small .toggle-bg2 {
    font-size: 10px;
}
.toggle-bg2 {
    background: #ffffff;
    border-radius: 32px;
    border: 1px solid #e6e6e6;
    display: block;
    float: left;
    height: 25px;
    position: relative;
    width: 55px;
    transition-duration: 0.2s;
}
.toggle-bg2 label.off {
    right: 8px;
    color: #666;
}
.toggle-bg2 input:checked {
    z-index: 0;
}
.toggle-bg2 input {
    margin: 0;
    padding: 0;
    height: 100%;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
    z-index: 2;
    zoom: 1;
}
.toggle-bg2 input:checked ~ .switch {
    left: 2px;
}

.ac-gn-link-apple-active{
    background-size: 14px 44px;
    background-repeat: no-repeat;
    background-position: center center;
    width: 14px;
    padding: 5px;
    background-color: #d6f9ff;
}

.loading{
    position: absolute;
    height: 100vh;

    display: none;
    width: 1436px;
    background: rgb(0 0 0 / 43%);
    /* bottom: -149%; */
    justify-content: center;
    align-items: center;
    z-index: 5555;
}
.toggle-bg{
    position: inherit;
}
.biab_body .biab_table-holder table tbody tr td.biab_odd {
    background: #dfdfdf !important;
}
.ac-gn-link-apple-btn123{
    background: #8d8080 !important;
}
.tag-live {
    position: absolute;
    top: -1px;
    right: 3px;
    min-width: 23px;
    height: 12px;
    border-radius: 3px;
    padding-right: 3px;
    color: #ffffff;
    text-align: center;
    font-size: 10px;
    line-height: 12px;
    background-image: linear-gradient(
180deg, #fb3434 0%, #e80505 100%);
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 50%);
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    
}
.tag-live strong {
    flex: 1;
    height: 100%;
    margin-right: 3px;
    padding: 0 2px;
    border-radius: 3px 0px 0px 3px;
    background-image: linear-gradient(
180deg, #fff 0%, #eee 89%);
justify-content: center;
    align-items: center;
    font-size: 0;
    text-indent: -99999px;
    display: flex;
}
.tag-live strong:before {
    width: 12px;
    height: 8px;
    background: url('data:image/svg+xml,<svg width="14" height="8" xmlns="http://www.w3.org/2000/svg"><g fill="rgb(255,0,0)" fill-rule="evenodd"><path d="M12.012 0l-.698.727c1.734 1.808 1.734 4.738 0 6.546l.698.727c2.117-2.207 2.117-5.79 0-8zM10.3 1.714l-.7.735c.967 1.014.967 2.66 0 3.673l.7.735c1.352-1.418 1.352-3.721 0-5.143zM1.588 0l.698.727c-1.734 1.808-1.734 4.738 0 6.546L1.588 8c-2.117-2.207-2.117-5.79 0-8zM3.3 1.714l.7.735c-.967 1.014-.967 2.66 0 3.673l-.7.735c-1.352-1.418-1.352-3.721 0-5.143z"/><circle cx="6.8" cy="4.4" r="1.6"/></g></svg>') no-repeat;
    background-size: contain;
}
.tag-live:after {
    content: "";
    border: solid;
    border-color: #ffdddd transparent;
    border-width: 6px 3px 0 3px;
    left: 50%;
    transform: translateX(-50%);
    bottom: -5px;
    position: absolute;
}
.tag-live strong:before{
    content: ""

}
.fa-bell-on::before{
    content: "\f0f3";
    color: red;
    margin-right: 3px;
    font: normal normal normal 12px/1 FontAwesome;
}
.biab_multi-market-title{
    padding: 4px 9px;
    line-height: 18px;
    font-size: 13px;
    background: #00a76f;
    color: #fff;
    padding-right: 85px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    position: relative;
    font-weight: bold;
}
.min-inp{
    width: 62px;
    height: 24px;
    margin-left: 4px;
    border: 1px solid gray;
    padding-left: 5px;
    font-weight: bold;
    font-size: 14px;

}
.fancyrunnername{
    width: 200px;
    height: 25px;
    margin-left: 4px;
    border: none;
    font-weight: bold;
    font-size: 11px;
}
#inpid{
    height:16px;
}
.fa-arrow-circle-down::before {
    content: "\f0ab";
    font: normal normal normal 22px/1 FontAwesome;
    color: cornflowerblue;
}
#colormaker{
    background: #efefef !important;
    border: 1px solid #ebe6e6 !important;
}
.tdbtn{
    margin: auto;
    padding: 5px 10px;
    border-radius: 4px;
    color: white;
    background: teal;
    display: flex;
}
.fa-plus-circle:before{
    content: "\f055";
    color: #333;
    display: flex;
    margin: auto;
    margin-left: 42px;
    font: normal normal normal 27px/1 FontAwesome;
}